<template lang="pug">
v-app
  VitePwaManifest
  v-app-bar(:title="`SATO48-20${openFeature.getNumber('current_film_festival')} Selection Committee: ${filmGroup?.name}`")
    v-spacer
    v-btn(icon="mdi-close" @click="close")
  slot
  NotifySnackbar
</template>

<script setup lang="ts">
useHead({
  titleTemplate: (title) => {
    return title
      ? `${title} - SATO48 Selection Committee`
      : 'SATO48 Selection Committee'
  },
})

const openFeature = useOpenFeature()
const { filmGroup } = storeToRefs(useSelectionCommittee())

const close = () => window.close()
</script>

<style>
p {
  margin-bottom: 10px;
}
</style>
